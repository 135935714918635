<script lang="ts">
	import { page } from '$app/stores'
	import { Root, Image, Fallback } from '$lib/components/ui/avatar'
	import { Separator } from '$lib/components/ui/separator'
	import { specializations } from '$lib/constants'
	import * as DropdownMenu from '$lib/components/ui/dropdown-menu'
	import { Button } from '$lib/components/ui/button'
	import Calendar from 'lucide-svelte/icons/calendar'
	import Book from 'lucide-svelte/icons/book'
	import H2 from './H2.svelte'

	import { logout } from '$lib/firebase-client/auth'
	import { user } from '$lib/stores/userStore'
	import ChevronDown from 'lucide-svelte/icons/chevron-down'
	import ChevronRight from 'lucide-svelte/icons/chevron-right'
	import { parsedToken } from '$lib/stores/parsedTokenStore'

	// Retrieve user store from context
	export let isLoggedIn: boolean = Boolean($user?.uid)
	$: isLoggedIn = Boolean($user?.uid)
	$: isAdmin = $parsedToken?.role === 'admin'
	$: isDoctor = isAdmin || Boolean($parsedToken?.doctor)
	let dropdownOpen = false
</script>

<header class="flex h-20 w-screen justify-between bg-white px-5 shadow-md dark:bg-black">
	<nav class="justify-left flex w-1/2 items-center gap-4 sm:gap-16">
		<div class="flex items-center justify-between gap-4">
			<a href="/">
				<Root class="self-center">
					<Image src="/logo.webp" alt="HSB Technologies" class="hover:scale-110" />
					<Fallback>L</Fallback>
				</Root>
			</a>
			<H2 class="hidden text-black dark:text-white sm:flex"
				>HSB <span class="ml-2 hidden md:block"> Technologies</span></H2
			>
		</div>

		<ul class="flex gap-8">
			<li>
				<DropdownMenu.Root closeOnOutsideClick closeOnItemClick bind:open={dropdownOpen}>
					<DropdownMenu.Trigger>
						<Button class="bg-green-300 text-black">
							<Book class="mr-2 hidden h-4 w-4 sm:block" />
							Spesialiseringer
							{#if !dropdownOpen}
								<ChevronDown class=" mt-0.5"></ChevronDown>
							{:else}
								<ChevronRight class="mt-0.5"></ChevronRight>
							{/if}
						</Button></DropdownMenu.Trigger
					>
					<DropdownMenu.Content>
						<DropdownMenu.Item href="/">
							<Button variant="link">Alle</Button>
						</DropdownMenu.Item>
						<Separator></Separator>
						{#each specializations as specialization}
							<DropdownMenu.Item href="/spesialiseringer/{specialization.specialization}">
								<Button variant="link">{specialization.name}</Button>
							</DropdownMenu.Item>
						{/each}
					</DropdownMenu.Content>
				</DropdownMenu.Root>
			</li>
			<li
				aria-current={$page.url.pathname === '/bookinger' ? 'page' : undefined}
				class="hidden lg:block"
			>
				<Button href="/bookinger" class="bg-green-300 text-black">
					<Calendar class="mr-2 h-4 w-4" />
					Mine bookinger
				</Button>
			</li>
		</ul>
	</nav>

	<div class="flex w-10 flex-col justify-center sm:mr-12">
		<DropdownMenu.Root>
			<DropdownMenu.Trigger>
				<Root class="self-center">
					<Image src="/avatar-placeholder.webp" alt="Avatar placeholder" class="hover:scale-110" />
					<Fallback>H</Fallback>
				</Root></DropdownMenu.Trigger
			>
			<DropdownMenu.Content>
				<DropdownMenu.Group>
					{#if isLoggedIn}
						<DropdownMenu.Item href="/profil"
							><Button variant="link">Min profil</Button></DropdownMenu.Item
						>
						<DropdownMenu.Separator />
						<DropdownMenu.Item on:click={() => logout()}
							><Button variant="link">Logg ut</Button></DropdownMenu.Item
						>
					{:else}
						<DropdownMenu.Item href="/login"
							><Button variant="link">Logg inn</Button></DropdownMenu.Item
						>
						<DropdownMenu.Separator />
						<DropdownMenu.Item href="/register"
							><Button variant="link">Registrer</Button></DropdownMenu.Item
						>
					{/if}
					{#if isAdmin}
						<DropdownMenu.Item href="/admin/soknader"
							><Button variant="link">Se innsendte søknader</Button></DropdownMenu.Item
						>
					{/if}
					{#if isAdmin}
						<DropdownMenu.Item href="/admin/bookinger"
							><Button variant="link">Se alle bookinger</Button></DropdownMenu.Item
						>
					{/if}
					{#if isDoctor}
						<DropdownMenu.Item href="/pasienttimer"
							><Button variant="link">Se pasienttimer</Button></DropdownMenu.Item
						>
					{/if}
				</DropdownMenu.Group>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	</div>
</header>
