<script lang="ts">
	import Button from '$lib/components/ui/button/button.svelte'
</script>

<footer class="flex min-h-32 flex-col justify-center bg-black">
	<ul class="flex w-full flex-col items-center justify-center sm:flex-row sm:gap-10">
		<li>
			<Button class="text-sm font-medium text-white" variant="link" href="/personvern"
				>Personvernserklæring</Button
			>
		</li>
		<li>
			<Button class="text-sm font-medium text-white" variant="link" href="/cookiepolicy"
				>Cookie policy</Button
			>
		</li>
		<li>
			<Button class="text-sm font-medium text-white" variant="link" href="/betingelser"
				>Vilkår for bruk</Button
			>
		</li>
		<li>
			<Button class="text-sm font-medium text-white" variant="link" href="/salgsvilkaar"
				>Salgsvilkår</Button
			>
		</li>
	</ul>
</footer>
